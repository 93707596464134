import React from 'react';

const Error = ({ location }) => {
	const type = location.state.type;
	const message = location.state.message;

	return (
		<div className="center">
			{
				type === 'error' ?
					<>
						<h2>Error Encountered</h2>
						<p>There was a problem loading the form. Please contact the administrator of this site for assistance.</p>
					</> : null
			}
			{
				type === 'submission' ?
					<>
						<h2>Submission Incomplete</h2>
						<p>There was a problem processing your submission. Please contact the administrator of this site for assistance.</p>
					</> : null
			}
			
			<p>{message}</p>
		</div>
	);
}

export default Error;