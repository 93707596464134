import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Form } from '@formio/react';
import { LoadingIcon } from '../../components/LoadingIcon';

import '../../../node_modules/formiojs/dist/formio.full.min.css';
import './FormLoader.scss';
import { Complete } from '../Complete';

const FormLoader = () => {
	const history = useHistory();

	let { directorypath, formpath } = useParams();
	const [formSchema, setFormSchema] = useState({});
	const [isLoading, setIsLoading] = useState(true);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [confirmationMessage, setConfirmationMessage] = useState('');

	useEffect(() => {
		getForm();
	}, []);

	const getForm = () => {
		fetch(`/form/${directorypath}/${formpath}`).then((response) => {
			setIsLoading(false);

			if (response?.ok) {
				response.json().then((data) => {
					if (data) {
						setFormSchema(data);
						window.dataLayer.push({
							'event': 'form-load'
						});
					}
				});
			} else {
				return response.json().then((json) => {
					throw json.message;
				});
			}
		}).catch((error) => {
			console.log('[get] There was an error retrieving the form data.');
			goToError(error);
		});
	}

	const formSubmit = (submission) => {
		const header = {
			method: 'post',
			headers: { 'content-type': 'application/json' },
			body: JSON.stringify(submission)
		}

		setIsLoading(true);
		setIsSubmitted(true);
		window.scrollTo(0, 0);

		return fetch(`/form/${directorypath}/${formpath}`, header).then((response) => {
			if (response?.ok) {
				return response.json().then((json) => {
					setIsLoading(false);
					window.dataLayer.push({
						'event': 'form-submission'
					});
					history.push(`/${directorypath}/${formpath}/complete`, { message: json.message });
				});
			} else {
				return response.json().then((json) => {
					throw json;
				});
			}
		}).catch((error) => {
			console.log('[submit] There was an error submitting the data.');
			goToError(error.message, error.type);
		});
	}

	const goToError = (errorMessage, errorType = 'error') => {
		history.push(`/${directorypath}/${formpath}/error`, { type: errorType, message: errorMessage });
	}

	return (
		<div className={`${directorypath}-${formpath}`}>
			<div className="center">
				<LoadingIcon isLoading={isLoading} />
			</div>

			{
				!isSubmitted ?
					<Form
						form={formSchema}
						onSubmit={formSubmit}
					/>
				:
					null
			}
		</div>
	);
}

export default FormLoader;
