import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { Layout } from './views/Layout';
import { Home } from './views/Home';
import { FormLoader } from './views/FormLoader';
import { Complete } from './views/Complete';
import { Error } from './views/Error';

import './custom.css'

export default class App extends Component {
	static displayName = App.name;

	render() {
		return (
			<>
				<Route exact path='/' component={Home} />
				<Layout>
					<Route exact path='/:directorypath/:formpath' component={FormLoader} />
					<Route exact path='/:directorypath/:formpath/complete' component={Complete} />
					<Route exact path='/:directorypath/:formpath/error' component={Error} />
				</Layout>
			</>
		);
	}
}
