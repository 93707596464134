import React, { Component } from 'react';

import './Home.scss';

export default class Home extends Component {
	static displayName = Home.name;

	render() {
		return (
			<div className="home">
				<div className="middle">
					<p>This domain manages shared applications for the BC Health Authorities</p>
					<ul className="logo-list">
						<li>
							<img src={`${process.env.PUBLIC_URL}/assets/images/logo-phsa.png`} className="logo" alt="PHSA"></img>
						</li>
					</ul>
				</div>
				<div className="footer">
					<p className="contact-us">
						provided by Provincial Digital Health and Information Services, Web Solutions
					</p>
				</div>
			</div>
		);
	}
}
