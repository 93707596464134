import React from 'react';

import './LoadingIcon.scss';

const LoadingIcon = ({ isLoading }) => {

	if (isLoading) {
		return (
			<div className="overlay-background">
				<i className="fa fa-refresh fa-spin button-icon-right" />
			</div>
		);
	} else {
		return null;
	}
};

export default LoadingIcon;