import React from 'react';

const Complete = ({ location }) => {
	const message = location.state.message;

	return (
		<div className="center">
			<h2>Submission Complete</h2>

			{
				message ?
					<div dangerouslySetInnerHTML={{ __html: message }}></div>
				:
					<p>Thank you for your submission.</p>
			}
		</div>
	);
}

export default Complete;